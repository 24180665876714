import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import classNames from "classnames";
import { MoreVert } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlined from "@material-ui/icons/LockOutlined";
import libraryPageStyle from "../../Library/LibraryPageStyle";
import PostType from "../../molecules/PostType/PostType";
import PostAttachments from "../../organisms/PostAttachments/PostAttachments";
import PostComments from "../../organisms/PostComments/PostComments";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Card from "components/Card/Card";
import defaultAvatar from "assets/img/placeholder.jpg";
import {
  getPostDetails,
  setPostDetails,
  generalPostsSelectors,
} from "../../../store/generalPostsSlice";
import PostAttachmentsCarousel from "../../organisms/PostAttachmentsCarousel/PostAttachmentsCarousel";
import EventDetails from "../../molecules/EventDetails/EventDetails";
import Paths from "../../../staticData/Paths";
import {
  generalPostActionTypes,
  usePostCard,
} from "../../../utils/hooks/postCardOptions";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(libraryPageStyle);

function stripHtml(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

export default function PostDetailsPage() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const postDetails = useSelector(generalPostsSelectors.postDetails);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getPostDetails(id)).then((data) => {
      dispatch(setPostDetails(data?.singleArticleResponse || data));
    });
  }, [dispatch, id]);

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluidView,
    classes.imgPosition
  );

  const createdDateTime = new Date(`${postDetails?.createdOn}Z`).toLocaleString(
    "en-GB",
    {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }
  );

  const { handleActions, getOptionsList } = usePostCard(
    generalPostActionTypes,
    true
  );
  const handleActionsByDetails = handleActions({ post: postDetails });

  return (
    <div
      className={classes.container}
      style={{
        paddingTop: "70px",
      }}
    >
      <Helmet>
        <meta
          property="og:title"
          content={postDetails?.title || "Post Details"}
        />
        <meta
          property="og:description"
          content={stripHtml(postDetails?.body) || "Details of the post"}
        />
        <meta
          property="og:keywords"
          content={
            postDetails?.tags?.map((x) => x?.tagName).join(", ") ||
            "post, details"
          }
        />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        {postDetails?.attachments?.length > 0 && (
          <meta
            property="og:image"
            content={
              postDetails.attachments[0].imageUrl ||
              postDetails.attachments[0].url
            }
          />
        )}
      </Helmet>

      <GridContainer justify={"center"}>
        <GridItem xs={10}>
          <Card blog>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  padding: "1rem 0",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Link
                    to={Paths.otherUserAccount.replace(
                      ":id",
                      postDetails?.user?.id
                    )}
                  >
                    <img
                      src={postDetails?.user?.profilePicture || defaultAvatar}
                      alt="User profile"
                      className={imageClasses}
                    />
                  </Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <h4
                    className={classes.title}
                    style={{
                      cursor: "pointer",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: "1",
                      WebkitBoxOrient: "vertical",
                    }}
                    onClick={() =>
                      dispatch(
                        push(
                          Paths.otherUserAccount.replace(
                            ":id",
                            postDetails?.user?.id
                          )
                        )
                      )
                    }
                  >
                    {postDetails?.user?.userName}
                  </h4>
                  <div style={{ display: "flex" }}>
                    <PostType postData={postDetails} />
                    {postDetails?.private && (
                      <LockOutlined
                        fontSize={"small"}
                        style={{ marginLeft: "0.3rem" }}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    top: "-10px",
                    justifyContent: "flex-end",
                    display: "flex",
                    flex: "1",
                  }}
                >
                  <span
                    style={{
                      top: "15px",
                      position: "relative",
                      margin: "0 1rem",
                    }}
                  >
                    {createdDateTime}
                  </span>
                  <CustomDropdown
                    caret={false}
                    hoverColor="primary"
                    buttonText={<MoreVert />}
                    onClick={(option) => {
                      handleActionsByDetails({ option });
                    }}
                    dropPlacement={"bottom-end"}
                    buttonProps={{
                      className: classes.imageDropdownButton,
                      color: "transparent",
                      justIcon: true,
                    }}
                    dropdownList={getOptionsList(postDetails)}
                  />
                </div>
              </div>
              <hr />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {postDetails?.eventData && (
                  <EventDetails eventData={postDetails?.eventData} />
                )}

                <div
                  dangerouslySetInnerHTML={{
                    __html: postDetails?.body,
                  }}
                />

                <PostAttachmentsCarousel
                  attachments={postDetails?.attachments}
                  previewOnly
                />

                {postDetails?.attachments?.length > 0 && (
                  <div>
                    <hr />
                    <PostAttachments
                      attachments={postDetails?.attachments}
                      displayMode={"view"}
                    />
                  </div>
                )}
              </div>
              <hr />
              <PostComments />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
